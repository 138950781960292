@font-face{
	font-family: CeraPro;
	font-display: swap;
	src: url("../fonts/CeraPro-Black.woff2") format("woff2"), url("../fonts/CeraPro-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
}
@font-face{
	font-family: CeraPro;
	font-display: swap;
	src: url("../fonts/CeraPro-Medium.woff2") format("woff2"), url("../fonts/CeraPro-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}
@font-face{
	font-family: CeraPro;
	font-display: swap;
	src: url("../fonts/CeraPro-Bold.woff2") format("woff2"), url("../fonts/CeraPro-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
@font-face{
	font-family: CeraPro;
	font-display: swap;
	src: url("../fonts/CeraPro-Light.woff2") format("woff2"), url("../fonts/CeraPro-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}
@font-face{
	font-family: CeraPro;
	font-display: swap;
	src: url("../fonts/CeraPro-Regular.woff2") format("woff2"), url("../fonts/CeraPro-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
